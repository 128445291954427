var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addAndEditBank"},[_c('van-sticky',[_c('van-nav-bar',{attrs:{"title":"快捷支付","left-arrow":""},on:{"click-left":_vm.onClickLeft}})],1),_c('div',[_c('van-form',{ref:"vanForm"},[_c('div',{staticClass:"vanFormDiv",staticStyle:{"margin":"10px"}},[_c('van-cell',{staticStyle:{"border-radius":"10px 10px 0 0"},attrs:{"title":"输入卡号添加"}}),_c('van-field',{attrs:{"name":"cardId","label":"卡号","placeholder":"请输入银行卡号","rules":[
          { required: true, message: '请输入银行卡号' },
          {
            required: true,
            message: '银行卡号格式限制为10位至30位数字',
            validator: function validator(val) {
              return /^[1-9]\d{9,29}$/.test(val);
            }, 
          } ]},on:{"blur":_vm.cardIdBlur},model:{value:(_vm.form.cardId),callback:function ($$v) {_vm.$set(_vm.form, "cardId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.cardId"}}),_c('van-field',{attrs:{"center":"","clearable":"","label":"手机号","placeholder":"请输入银行预留手机号","rules":[
                  { required: true, message: '请输入银行预留手机号' },
                  {
                      pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
                      message: '请输入正确的银行预留手机号',
                  } ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{staticStyle:{"color":"#212c67","background-color":"transparent","border":"none","font-size":"14px"},attrs:{"size":"small","type":"info","native-type":"button","disabled":!_vm.canClick},on:{"click":function($event){$event.stopPropagation();return _vm.sendSms.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.content))])]},proxy:true}]),model:{value:(_vm.form.telNo),callback:function ($$v) {_vm.$set(_vm.form, "telNo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.telNo"}}),_c('van-field',{staticStyle:{"border-radius":"0 0 10px 10px"},attrs:{"name":"smsCode","label":"验证码","rules":[
                  { required:true, message: '请输入短信验证码'}
              ],"placeholder":"请输入短信验证码"},model:{value:(_vm.form.smsCode),callback:function ($$v) {_vm.$set(_vm.form, "smsCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.smsCode"}})],1),_c('div',{staticClass:"vanFormDiv",staticStyle:{"margin":"10px"}},[_c('van-cell',{staticStyle:{"border-radius":"10px 10px 0 0"},attrs:{"title":"招行借记卡请输入"}}),_c('van-field',{staticStyle:{"border-radius":"0 0 10px 10px"},attrs:{"name":"protocolNo","label":"协议号","placeholder":"请输入协议号","rules":[
                    { required: _vm.cardTypeList.cardType=='DC'&&_vm.cardTypeList.bankCode=='CMB'?true:false, message: '请输入协议号' } ]},model:{value:(_vm.form.protocolNo),callback:function ($$v) {_vm.$set(_vm.form, "protocolNo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.protocolNo"}})],1),_c('div',{staticClass:"vanFormDiv",staticStyle:{"margin":"10px"}},[_c('van-cell',{staticStyle:{"border-radius":"10px 10px 0 0"},attrs:{"title":"信用卡请输入"}}),_c('van-field',{attrs:{"name":"vipCode","label":"信用编码","placeholder":"输入银行卡信用编码","rules":[
                    { required: _vm.cardTypeList.cardType=='CC'?true:false, message: '输入银行卡信用编码' } ]},model:{value:(_vm.form.vipCode),callback:function ($$v) {_vm.$set(_vm.form, "vipCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.vipCode"}}),_c('van-field',{attrs:{"name":"expiration","label":"有效期","placeholder":"输入银行卡有效期","rules":[
                    { required: _vm.cardTypeList.cardType=='CC'?true:false, message: '输入银行卡有效期' } ]},model:{value:(_vm.form.expiration),callback:function ($$v) {_vm.$set(_vm.form, "expiration", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.expiration"}})],1),_c('div',{staticClass:"save"},[_c('van-button',{staticClass:"van-button--info",attrs:{"block":"","native-type":"submit","loading":_vm.saving},on:{"click":_vm.onSubmit}},[_vm._v("确认添加")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }