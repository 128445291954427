<template>
    <div class="addAndEditBank">
      <van-sticky>
        <van-nav-bar title="快捷支付" left-arrow @click-left="onClickLeft">
        </van-nav-bar>
      </van-sticky>
      <div>
      
        <van-form ref="vanForm">
          <div class="vanFormDiv" style="margin:10px;">
            <van-cell title="输入卡号添加" style="border-radius: 10px 10px 0 0 ;"/>
            <van-field
              v-model.trim="form.cardId"
              name="cardId"
              label="卡号"
              placeholder="请输入银行卡号"
              @blur="cardIdBlur"
              :rules="[
              { required: true, message: '请输入银行卡号' },
              {
                required: true,
                message: '银行卡号格式限制为10位至30位数字',
                validator(val) {
                  return /^[1-9]\d{9,29}$/.test(val);
                }, 
              },]"
            >
            </van-field>
            <van-field
                  v-model.trim="form.telNo"
                  center
                  clearable
                  label="手机号"
                  placeholder="请输入银行预留手机号"
                  :rules="[
                      { required: true, message: '请输入银行预留手机号' },
                      {
                          pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
                          message: '请输入正确的银行预留手机号',
                      },
                  ]"
              >
                  <template #button>
                      <van-button size="small" type="info" native-type="button" @click.stop="sendSms" style="color:#212c67;background-color: transparent;border:none;font-size: 14px;" :disabled="!canClick">{{content}}</van-button>
                  </template>
            </van-field>

            <van-field
              v-model.trim="form.smsCode"
              name="smsCode"
              style="border-radius: 0 0 10px 10px ;"
              label="验证码"
              :rules="[
                      { required:true, message: '请输入短信验证码'}
                  ]"
              placeholder="请输入短信验证码"
            />
          </div>

          <div class="vanFormDiv" style="margin:10px;">
            <van-cell title="招行借记卡请输入" style="border-radius: 10px 10px 0 0 ;" />
               <!-- 招行借记卡绑卡需要上送 -->
            <van-field
                v-model.trim="form.protocolNo"
                name="protocolNo"
                label="协议号"
                placeholder="请输入协议号"
                style="border-radius: 0 0 10px 10px ;"
                :rules="[
                        { required: cardTypeList.cardType=='DC'&&cardTypeList.bankCode=='CMB'?true:false, message: '请输入协议号' },
                    ]"
              />
          </div>

          <div class="vanFormDiv" style="margin:10px;">
            <van-cell title="信用卡请输入" style="border-radius: 10px 10px 0 0 ;"/>
            <van-field
                v-model.trim="form.vipCode"      
                name="vipCode"
                label="信用编码"
                placeholder="输入银行卡信用编码"
                :rules="[
                        { required: cardTypeList.cardType=='CC'?true:false, message: '输入银行卡信用编码' },
                    ]"
              />    
              <!-- 若银行卡为信用卡时必填               -->
              <van-field
                v-model.trim="form.expiration"
                name="expiration"
                label="有效期"
                placeholder="输入银行卡有效期"
                :rules="[
                        { required: cardTypeList.cardType=='CC'?true:false, message: '输入银行卡有效期' },
                    ]"
              />
         
          </div>
      
          <!-- 若银行卡为信用卡时必填，银行卡背面签名条末三位 -->
          
          <div class="save" >
            <van-button block native-type="submit" :loading="saving" class="van-button--info" @click="onSubmit"
              >确认添加</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
  </template>
  
  <script>
  import {  addFastCardSms ,saveFastCard } from '@/api/user';
  import getBankcardinfo from 'bankcardinfo'
  import { Toast } from "vant";
  export default {
    name: "addAndEditBank",
    data() {
      return {
        form:{
          cardId:'',
          telNo:'',
          smsCode:''
        },
        saving: false,
        cardTypeList:{},
        content: "获取验证码",
        canClick: true,
        count:"",//倒计时
        timer:null,//计时器
      };
    },
    methods: {
      onClickLeft() {
        this.$router.back();
      },
      //判断银行卡类型
      cardIdBlur(){
        getBankcardinfo.getBankBin(this.form.cardId, (err, data) => {
          if (!err) {
            this.cardTypeList = data
          }
        })
      },
      // 发送手机验证码
      sendSms(){
        if(this.form.cardId=='')
        return Toast ('银行卡号不能为空')

        if(this.form.telNo=='')
        return Toast ('手机号不能为空')
     
        addFastCardSms(this.form).then(res=>{
          if(res){
            Toast("发送成功");
            this.form.applyId=res
            if (!this.timer) {
              this.count = 60
              this.canClick = false
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= 60) {
                  this.count--
                  this.content = this.count+'s重新发送'
                } else {
                  this.canClick = true
                  this.content = '获取验证码'
                  clearInterval(this.timer) // 清除定时器
                  this.timer = null
                }
              }, 1000)
            }
          }else{

          }
        })
      },

      onSubmit() {
        this.$refs.vanForm.validate().then(() => {
            this.saving = true;
            Toast.loading({})
            saveFastCard(this.form.applyId,this.form.smsCode).then(
              (res) => {
                this.saving = false;
                if(res){
                  Toast("保存成功");
                  this.$router.back();
                }

              }
            )
        }).catch(() => {
        })
      
      },
    },
  };
  </script>
  
  <style scoped lang='less'>
  .addAndEditBank {
    height: 100vh;
    .bank-title {
      color: #212121;
      margin: 28px 17px 20px;
      font-size: 16px;
      > div {
        font-weight: 700;
      }
      > p {
        color: #8bc3ff;
        margin-top: 8px;
        font-size: 12px;
      }
    }
    
    .save {
      margin:60px 16px 16px;
      display: flex;
      justify-content: center;
      .van-button--info{
        background-color: transparent;
        border-color: #212c67;
        color: #212c67;
        width: 50%;
      }
    }
  }
  ::v-deep .van-nav-bar {
    background-color: #212c67;
    .van-nav-bar__title{
      color:#fff;
    }
    .van-icon {
      color: #fff;
      font-size: 18px;
    }
  }
  </style>
  